//This is a temp placeholder for the CMS Image component
//TODO: implement this properly
import React, { memo } from 'react';
import { css, useTheme } from '@emotion/react';
import type { ImageEntryType } from 'tsconfig/types.d';
import Image from 'next/image';
import { inspectorMode, useScreenLayout } from 'common-ui';
import { Link } from '../../Link/Link';

export type CmsImageProps = {
  contentItem?: ImageEntryType;
  //image defaults to filling the width of its container (while mainting aspect ratio)
  disableAutoSize?: boolean;
  isNextImg?: boolean;
  width?: number;
  height?: number;
  sizes?: string;
  fill?: boolean;
  blurDataURL?: string;
  placeholder?: 'empty' | 'blur' | `data:image/${string}` | undefined;
};

export const CmsImage = memo((props: CmsImageProps) => {
  const {
    disableAutoSize,
    contentItem,
    isNextImg,
    height,
    width,
    sizes,
    fill = false,
    blurDataURL,
    placeholder = 'empty',
  } = props;

  const theme = useTheme();
  const { isNotDesktop } = useScreenLayout();

  if (!contentItem) {
    return null;
  }
  const { largeDeviceImage, smallDeviceImage, altText, url = '' } = contentItem.fields;

  const imgContainStyle = css`
    max-width: 100%;
    height: auto;
    object-fit: contain;
    position: relative !important;
  `;

  const intrinsicWidth = largeDeviceImage?.fields?.file?.details?.image?.width;
  const intrinsicHeight = largeDeviceImage?.fields?.file?.details?.image?.height;

  const mediaMd = theme.breakpoints.md ? theme.breakpoints.md.replace('@media', '') : '';

  const conditionalImageProps = fill
    ? { fill }
    : disableAutoSize && width
      ? { width, height }
      : { width: intrinsicWidth, height: intrinsicHeight, sizes };

  const imageSrc =
    isNotDesktop && smallDeviceImage?.fields?.file?.url
      ? smallDeviceImage?.fields?.file?.url
      : largeDeviceImage?.fields?.file?.url;
  const mobileImage = smallDeviceImage?.fields?.file?.url || largeDeviceImage?.fields?.file?.url;

  let cmsImageContent = isNextImg ? (
    <Image
      src={imageSrc || ''}
      alt={altText || 'banner'}
      className="banner-img"
      css={imgContainStyle}
      blurDataURL={blurDataURL}
      placeholder={placeholder}
      data-testid="next-image"
      {...conditionalImageProps}
      {...inspectorMode(contentItem?.sys?.id, (isNotDesktop && 'smallDeviceImage') || 'largeDeviceImage')}
    />
  ) : (
    <picture {...inspectorMode(contentItem?.sys?.id, (isNotDesktop && 'smallDeviceImage') || 'largeDeviceImage')}>
      <source media={mediaMd} srcSet={largeDeviceImage?.fields?.file?.url} />
      <source srcSet={mobileImage} width={'100%'} height={'100%'} />
      <img
        width={fill ? '100%' : disableAutoSize && width ? width : intrinsicWidth}
        loading="lazy"
        src={largeDeviceImage?.fields?.file?.url}
        alt={altText || 'banner'}
        className="banner-img"
        data-testid="cms-image"
      />
    </picture>
  );
  if (url) {
    cmsImageContent = <Link href={url}>{cmsImageContent}</Link>;
  }

  return (
    <div data-test="widget-CmsImage" className="cms-image-container">
      {cmsImageContent}
    </div>
  );
});

import { FC, memo, useEffect } from 'react';
import { TypeBasedConditionalContent } from '../../../contentful/CmsConditionalContainer/TypeBasedConditionalContent';
import { DLClickEventHandlerProps, LinkEntryType, TabEntryType } from 'tsconfig/types';
import { NavLink } from '@mantine/core';
import { MobileMenuNavItem, inspectorMode, parseMenuDataFromContentfulMegaNav } from 'common-ui';
import get from 'lodash/get';

interface RenderLevel1MenuProps {
  fields: any;
  dlClickEventHandler: DLClickEventHandlerProps;
  setStickyContent?: (value: LinkEntryType[]) => void;
  cb: (level2Menu: string) => void;
}

const RenderLevel1Menu: FC<RenderLevel1MenuProps> = ({ fields, setStickyContent, dlClickEventHandler, cb }) => {
  useEffect(() => {
    if (setStickyContent) {
      const sc = get(fields, 'stickyContent');
      sc && setStickyContent(sc as LinkEntryType[]);
    }
  }, [fields]);

  const renderNavMenu = (content: TabEntryType, index: number) => {
    const navlevel1Class = content.fields.emphasizeTitle ? 'level1Link emphasize' : 'level1Link';

    const menuData = parseMenuDataFromContentfulMegaNav(content);
    if (menuData.children && menuData.children.length > 0) {
      return (
        <NavLink
          key={menuData.title + index}
          className={navlevel1Class}
          label={menuData.title}
          rightSection={<i className="navMenuItemIcon" />}
          onClick={() => {
            dlClickEventHandler(menuData.title);
            cb(menuData.id);
          }}
          {...inspectorMode(content.sys.id, 'title')}
        />
      );
    }
    return (
      <a
        key={menuData.title + index}
        href={menuData.url}
        className={navlevel1Class}
        onClick={() => dlClickEventHandler(menuData.title)}
        {...inspectorMode(content.sys.id, 'title')}
      >
        <span>{(menuData as MobileMenuNavItem).title}</span>
      </a>
    );
  };

  return fields.tabPanes?.map((tab: TabEntryType, index: number) => {
    return (
      <TypeBasedConditionalContent<TabEntryType>
        content={tab}
        contentTypes={['tab']}
        key={tab.fields.title + `-tab-${index}`}
        fallback={content => renderNavMenu(content, index)}
      >
        {renderNavMenu(tab, index)}
      </TypeBasedConditionalContent>
    );
  });
};

export default memo(RenderLevel1Menu);

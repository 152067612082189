import get from 'lodash/get';
import type {
  BannerEntryType,
  ColumnEntryType,
  ColumnsEntryType,
  LinkEntryType,
  NavigationEntryType,
  RichTextEntryType,
  TabEntryType,
} from 'tsconfig/types';

export interface MobileMenuNavItem {
  contentType: string;
  contentItem: NavigationEntryType;
  title: string;
  url?: string;
  children?: LinkEntryType[];
  id: string;
}

export interface MobileMenuContentItem {
  contentType: string;
  contentItem: BannerEntryType | RichTextEntryType;
  id: string;
}

export type MobileMenuSubLevelNode = MobileMenuNavItem | MobileMenuContentItem;

export interface MobileMenuTopLevelNode {
  title: string;
  url?: string;
  children?: MobileMenuSubLevelNode[];
  id: string;
}

function parseMenuDataFromColumns(childColumns: ColumnsEntryType[]): MobileMenuSubLevelNode[] {
  const subLevelMenu: MobileMenuSubLevelNode[] = [];
  childColumns?.forEach(columns => {
    (columns?.fields?.content as ColumnEntryType[])?.forEach(column => {
      column?.fields.content?.forEach((columnSingle: any) => {
        const contentTypeId = get(columnSingle, 'sys.contentType.sys.id');

        if (contentTypeId === 'navigation') {
          const { title, sectionLink, content } = columnSingle.fields;
          subLevelMenu.push({
            title: title,
            url: sectionLink?.fields?.url,
            children: content,
            id: columnSingle.sys?.id,
            contentItem: columnSingle,
            contentType: contentTypeId,
          });
        } else if (contentTypeId === 'menuBanner') {
          subLevelMenu.push({
            contentType: contentTypeId,
            contentItem: columnSingle,
            id: columnSingle.sys?.id,
          });
        } else if (contentTypeId === 'richtext') {
          subLevelMenu.push({
            contentType: contentTypeId,
            contentItem: columnSingle,
            id: columnSingle.sys?.contentType?.sys,
          });
        }
      });
    });
  });
  return subLevelMenu;
}

export function parseMenuDataFromContentfulMegaNav(tab: TabEntryType) {
  const { title, tabUrl, content } = tab.fields;
  const childNav = parseMenuDataFromColumns(content as ColumnsEntryType[]);

  return {
    title: title,
    url: tabUrl,
    children: childNav,
    id: tab.sys?.id,
  } as MobileMenuTopLevelNode;
}

import { ThemeProvider } from '@emotion/react';
import { AlgoliaSearchInstant } from 'common-ui';
import { RecoilRoot } from 'common-state';
import HeaderFooterWrapper from './HeaderFooterWrapper';
import type { CommonEmbeddedHeaderFooterAppProps } from 'tsconfig/types';
import { RecoilInitEmbeddedWrapper } from './RecoilInitEmbeddedWrapper';
import RecoilNexus from 'recoil-nexus';
import { MantineProvider } from '@mantine/core';
import { Notifications } from '@mantine/notifications';

export function CommonEmbeddedHeaderFooterApp({ theme, GlobalStyles }: CommonEmbeddedHeaderFooterAppProps) {
  return (
    <RecoilRoot>
      <RecoilNexus />
      <MantineProvider withNormalizeCSS withGlobalStyles>
        <Notifications position="top-right" />
        <ThemeProvider theme={theme}>
          <GlobalStyles />
          <RecoilInitEmbeddedWrapper />
          <AlgoliaSearchInstant>
            <HeaderFooterWrapper config={window.newHeaderFooterInitData} />
          </AlgoliaSearchInstant>
        </ThemeProvider>
      </MantineProvider>
    </RecoilRoot>
  );
}

import { useRecoilState } from 'recoil';
import { notificationsAtom, NotificationType } from '../atoms/notificationsAtom';
import { cartNotification } from '../helpers/cartNotification';
import { fetchCart } from '../atoms/cartAtom';
import { notificationMessage } from '../content/notificationMessage';

//This is a hook called from ATG pages when they add to cart and we need to refresh header AND show a 'item added' notification
export function useCartGetAddedProduct(): {
  showProductAdded: (event?: any) => Promise<void>;
  showProductRemoved: () => Promise<void>;
} {
  const [, setNotification] = useRecoilState(notificationsAtom);

  const showProductAdded = async (event?: any) => {
    await fetchCart();
    const { itemcode: itemCode, quantity } = event?.detail ?? { itemcode: '', quantity: 0 };
    const notification = cartNotification(itemCode, quantity);
    setNotification([notification]);
  };

  const showProductRemoved = async () => {
    await fetchCart();
    setNotification([
      {
        type: NotificationType.REMOVED_FROM_CART,
        timeout: 5000,
        message: notificationMessage[NotificationType.REMOVED_FROM_CART],
        expires: new Date().getTime() + 5000,
      },
    ]);
  };
  return { showProductAdded, showProductRemoved };
}

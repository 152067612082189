import React, { memo } from 'react';
import { css, useTheme } from '@emotion/react';
import type { DLClickEventHandlerProps, NavigationEntryType } from 'tsconfig/types.d';
import { CmsLink } from '../CmsLink/CmsLink';
import { inspectorMode } from 'common-ui';
import { Accordion } from '@mantine/core';

export type CmsNavigationProps = {
  contentItem: NavigationEntryType;
  children?: React.ReactNode;
  className?: string;
  category?: string;
  dlClickEventHandler?: DLClickEventHandlerProps;
};

export const CmsNavigation = memo((props: CmsNavigationProps) => {
  const theme = useTheme();
  const { children, contentItem, className, dlClickEventHandler } = props;
  const { title, sectionLink, openAccordionByDefault } = contentItem.fields;
  const widgetTheme = theme.widgets.CmsNavigation || {};
  const wrapper = css`
      ${widgetTheme.wrapper}
    `,
    body = css`
      ${widgetTheme.body}
    `,
    sectionLinkFooter = css`
      ${widgetTheme.sectionLinkFooter}
    `;

  if (openAccordionByDefault) {
    return (
      <div data-test="widget-CmsNavigation" css={wrapper} className="accordion-navigation">
        <Accordion>
          <Accordion.Item value={title}>
            <Accordion.Control>
              <span {...inspectorMode(contentItem?.sys?.id, 'title')}>{title}</span>
            </Accordion.Control>
            <Accordion.Panel className="accordion-panel">{children}</Accordion.Panel>
          </Accordion.Item>
        </Accordion>
      </div>
    );
  }
  return (
    <div data-test="widget-CmsNavigation" css={wrapper} className={className}>
      <h4 {...inspectorMode(contentItem?.sys?.id, 'title')}>{title}</h4>
      <div css={body} className={className}>
        {children}
        {sectionLink && (
          <div css={sectionLinkFooter}>
            <CmsLink contentItem={sectionLink} category="main navbar link" dlClickEventHandler={dlClickEventHandler} />
            <i />
          </div>
        )}
      </div>
    </div>
  );
});

import { useTheme } from '@emotion/react';
import { useEffect, useState } from 'react';
import { getBreakpoint } from '../utils/grid.utils';

interface ScreenLayout {
  screen: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl';
  isMobile: boolean;
  isMobileView: boolean;
  isDesktop: boolean;
  isNotDesktop: boolean;
}

/**
 * [useScreenLayout This hook returns the currently active breakpoint]
 *
 * @return  {ScreenLayout}  [return Breakpoints]
 */
export const useScreenLayout = (): ScreenLayout => {
  const theme = useTheme();

  const initialState = getBreakpoint(theme);
  const [{ screen }, setState] = useState({ screen: initialState });

  useEffect(() => {
    const setScreen = () => {
      const currentScreen = getBreakpoint(theme) || 'xs';

      if (currentScreen !== screen) {
        setState({
          screen: currentScreen,
        });
      }
    };

    setScreen();

    //SSR typecheck
    if (typeof window !== 'undefined') {
      window.addEventListener('orientationchange', setScreen, false);
      window.addEventListener('resize', setScreen, false);
    }

    return () => {
      window.removeEventListener('orientationchange', setScreen);
      window.removeEventListener('resize', setScreen);
    };
  }, [theme, screen]);

  const isDesktopView = ['lg', 'xl', 'xxl'].includes(screen);
  const isNotDesktop = !(isDesktopView || screen === 'md');
  const isMobileView = screen === 'xs';

  // isMobile < 1230
  // isDesktop > 1230
  // isMobileView < 767
  // isNotDesktop < 1024
  return { screen, isMobile: !isDesktopView, isDesktop: isDesktopView, isMobileView, isNotDesktop };
};

import React from 'react';
import { css } from '@emotion/react';
import { Skeleton } from '../../Skeleton/Skeleton';
import { useTheme } from '@emotion/react';

const TrustpilotSkeleton = () => {
  const theme = useTheme();

  // Styles from the theme
  const skeletonWrapperStyle = css`
    ${theme.widgets.TrustPilotWidget?.skeletonWrapperStyle}
  `;

  const headingSkeletonStyle = css`
    ${theme.widgets.TrustPilotWidget?.headingSkeletonStyle}
  `;

  const reviewCardStyle = css`
    ${theme.widgets.TrustPilotWidget?.reviewCardStyle}
  `;

  const starsAndTitleStyle = css`
    ${theme.widgets.TrustPilotWidget?.starsAndTitleStyle}
  `;

  return (
    <div css={skeletonWrapperStyle} data-test="trustpilotSkeleton">
      {/* Header Section */}
      <div css={headingSkeletonStyle} data-test="headerSkeleton">
        <Skeleton height={32} width={100} />
        <Skeleton height={32} width={160} />
        <Skeleton height={18} width={120} mb={8} />
        <Skeleton height={28} width={80} />
      </div>

      {/* Review Cards Section */}
      <div
        css={{ display: 'flex', gap: '16px', overflowX: 'auto' }}
        data-test="reviewCardSection"
      >
        {[...Array(4)].map((_, index) => (
          <div
            css={reviewCardStyle}
            key={index}
            data-test="reviewCardSkeleton"
          >
            <div css={starsAndTitleStyle} data-test="starsAndTitleSkeleton">
              <Skeleton height={24} width={160} />
              <Skeleton height={16} width={120} />
            </div>
            <Skeleton height={14} width={180} />
            <Skeleton height={14} width={160} mb={8} />
            <Skeleton height={14} width={140} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default TrustpilotSkeleton;
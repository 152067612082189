import React, { memo, useState, useEffect } from 'react';
import get from 'lodash/get';
import { css, useTheme } from '@emotion/react';
import type {
  ArrayEntryType,
  ConditionalContainerEntryType,
  DLClickEventHandlerProps,
  TabEntryType,
  TabsEntryType,
} from 'tsconfig/types';
import { HoverCard } from '@mantine/core';
import { MainLargeNavGroup } from './MainLargeNavGroup';
import { TypeBasedConditionalContent } from '../../contentful/CmsConditionalContainer/TypeBasedConditionalContent';
import { inspectorMode, Link } from 'common-ui';

export type MainNavBarProps = {
  id?: string;
  cmsMainNav?: ArrayEntryType<TabsEntryType>;
  globalHeaderHeight: number;
  dlClickEventHandler: DLClickEventHandlerProps;
};

export const MainNavBar = memo(({ cmsMainNav = [], id, globalHeaderHeight, dlClickEventHandler }: MainNavBarProps) => {
  const theme = useTheme();
  const widgetTheme = theme.widgets.GlobalHeader || {};
  const wrapper = css`
      ${widgetTheme.mainNavBarWrapper}
    `,
    tabPopup = css`
      ${widgetTheme.mainNavBarMenuTabPane}
      ${theme.mixins.containerLayoutFixed}
    `,
    seoMenu = css`
      display: none;
    `;
  const [openMenus, setMenuOpen] = useState<number[]>([]);

  useEffect(() => {
    const htmlDoc = document.getElementsByTagName('html')?.[0];
    if (openMenus?.length > 0) {
      htmlDoc.style.overflow = 'hidden';
    } else {
      htmlDoc.style.overflow = 'visible';
    }
  }, [openMenus]);

  const hoverCardOpen = (index: number) => {
    setMenuOpen((prev: number[]) => [...prev, index]);
  };

  const hoverCardClose = (index: number) => {
    setMenuOpen((prev: number[]) => prev.filter(openMenu => openMenu !== index));
  };

  function renderNavMenu(tabPane: TabEntryType, index: number) {
    if (!tabPane?.fields) {
      return null;
    }
    const { title, content, tabUrl = '#', emphasizeTitle, stickyContent } = tabPane.fields;
    const tabInstanceId = get(tabPane, 'sys.id');
    const tabTitleClass = emphasizeTitle ? 'megaNavTab emphasize' : 'megaNavTab';

    /*
  TODO: resolve conflict between Next <Link/>s in some Mantine UI elements.
  The issue:
    * We need to use Link in Netlify for correct routing
    * We also need links to work in embedded CRA apps which means having an <a> tag inside Link
    * Next 13 deprecates this behaviour so we need to use legacyBehavior attr on Linkz
    * This resuls in links inside some Mantine UI elements not being initialised correctly
  Seems to relate to this ticket https://github.com/mantinedev/mantine/issues/2968
    */

    return (
      <div className="topLevelLink" key={tabInstanceId}>
        {content && content?.length > 0 ? (
          <HoverCard
            width="-webkit-fill-available"
            shadow="md"
            openDelay={500}
            closeDelay={500}
            onOpen={() => {
              hoverCardOpen(index);
              const navOverlayElement = document.querySelector('.nav-overlay') as HTMLElement;
              navOverlayElement.classList.add('active-nav-overlay');
            }}
            onClose={() => {
              hoverCardClose(index);
              const navOverlayElement = document.querySelector('.nav-overlay') as HTMLElement;
              navOverlayElement.classList.remove('active-nav-overlay');
            }}
            position="bottom"
          >
            <HoverCard.Target>
              <div>
                <Link
                  className={tabTitleClass}
                  href={tabUrl}
                  data-event="mainNavbarClick"
                  data-section={`main navbar:${title}`}
                  data-action="navigation"
                  data-name="main navbar"
                  data-text={title}
                  data-nav-label={title}
                  onClick={() => dlClickEventHandler(title)}
                  {...inspectorMode(tabInstanceId, 'title')}
                >
                  <span>{title}</span>
                </Link>
              </div>
            </HoverCard.Target>
            <HoverCard.Dropdown css={tabPopup}>
              <div className="header-main-nav-layout">
                <MainLargeNavGroup
                  globalHeaderHeight={globalHeaderHeight}
                  menuContent={content}
                  stickyContent={stickyContent}
                  dlClickEventHandler={data => {
                    dlClickEventHandler(`${title}:${data}`);
                  }}
                />
              </div>
            </HoverCard.Dropdown>
          </HoverCard>
        ) : (
          <div>
            <Link
              className={tabTitleClass}
              href={tabUrl}
              data-action="navigation"
              data-section={`main navbar:${title}`}
              data-name="main navbar"
              data-text={title}
              data-nav-label={title}
              onClick={() => dlClickEventHandler(title)}
              {...inspectorMode(tabInstanceId, 'title')}
            >
              <span>{title}</span>
            </Link>
          </div>
        )}
      </div>
    );
  }

  const renderTab = (tabPanes: (TabEntryType | ConditionalContainerEntryType)[]) => {
    return (
      tabPanes?.map((tab, index: number) => {
        return (
          <TypeBasedConditionalContent<TabEntryType>
            content={tab}
            contentTypes={['tab']}
            key={`${(tab as TabEntryType)?.fields?.title || (tab as ConditionalContainerEntryType)?.fields?.entryTitle}`}
            fallback={content => renderNavMenu(content, index)}
            enableLoader={true}
          >
            {renderNavMenu(tab as TabEntryType, index)}
          </TypeBasedConditionalContent>
        );
      }) || null
    );
  };

  const renderMegaNavTabs = () => {
    return (cmsMainNav as (TabsEntryType | ConditionalContainerEntryType)[])?.map(tabGroup => {
      return (
        <TypeBasedConditionalContent<TabsEntryType>
          content={tabGroup}
          contentTypes={['tabs']}
          key={`${tabGroup?.fields?.entryTitle}-mainNav`}
          fallback={content => renderTab(content?.fields?.tabPanes as TabEntryType[])}
          enableLoader={true}
        >
          <div>{renderTab((tabGroup as TabsEntryType)?.fields?.tabPanes as TabEntryType[])}</div>
        </TypeBasedConditionalContent>
      );
    });
  };

  //The html generated by our mega nav (when using 'keepMounted' flag) results a bloated static page render. Instead
  //we defer the main render until client load BUT still render a minimal hidden 'SEO menu' so that nav links are
  //still present for SEO purposes
  function renderSEOMenu() {
    return (cmsMainNav as TabsEntryType[])?.map((tabs: TabsEntryType) => {
      const tabInstanceId = get(tabs, 'sys.id');
      const links: any[] = [];

      function isContentType(node: any) {
        return node instanceof Object && node?.sys && node?.fields;
      }

      function iterateTree(node: any, uniqueKey: string, action: any) {
        if (isContentType(node)) {
          action(node, uniqueKey);

          if (node?.fields && typeof node?.fields === 'object') {
            for (const key in node?.fields) {
              const fieldNode = node?.fields[key];
              if (Array.isArray(fieldNode)) {
                fieldNode?.forEach(arrayNode => iterateTree(arrayNode, uniqueKey + arrayNode?.sys?.id, action));
              } else if (fieldNode instanceof Object) {
                iterateTree(fieldNode, uniqueKey + fieldNode?.sys?.id, action);
              }
            }
          }
        }
      }

      iterateTree(tabs, tabInstanceId, (node: any, uniqueKey: string) => {
        const typeId = node?.sys?.contentType?.sys?.id;
        if (isContentType(node) && typeId === 'link') {
          links.push(
            <a key={uniqueKey} href={node?.fields?.url}>
              {node?.fields?.title}
            </a>,
          );
        }
      });

      return <div key={tabInstanceId}>{links}</div>;
    });
  }

  return (
    <div data-test="widget-MainNavBar" css={wrapper} id={id}>
      <div className="topLevelLinks">{renderMegaNavTabs()}</div>
      <div css={seoMenu}>{renderSEOMenu()}</div>
    </div>
  );
});

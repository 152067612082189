import { css, useTheme } from '@emotion/react';
import React, { memo, useMemo } from 'react';
import type { ImageEntryType, RichTextEntryType } from 'tsconfig/types';
import { CmsRichText } from '../contentful/CmsRichText/CmsRichText';
import { CmsImage } from '../contentful/CmsImage/CmsImage';
import { inspectorMode } from 'common-ui';
import getPixalImageDataUrl from '../../utils/getPixalImageDataUrl';

export interface ArticleCardProps {
  entryTitle?: string;
  link: string;
  media?: ImageEntryType;
  primaryHeader: string;
  secondaryHeader?: string;
  content?: RichTextEntryType;
  publishedDate?: string;
  id?: string;
}

export const Article = memo(
  ({ link, media, primaryHeader, secondaryHeader, content, publishedDate, id }: ArticleCardProps) => {
    const formattedDate = useMemo(() => {
      if (publishedDate) {
        const date = new Date(publishedDate);
        const day = date.getDate();
        const month = new Intl.DateTimeFormat('en-US', { month: 'long' }).format(date);
        const year = date.getFullYear();
        return `${day} ${month}, ${year}`;
      }
      return '';
    }, [publishedDate]);

    const theme = useTheme();
    const wrapper = css`
      ${theme.widgets?.CmsArticle?.wrapper}
    `;

    return (
      <div css={wrapper}>
        <a href={link}>
          <CmsImage
            disableAutoSize={false}
            width={500}
            height={300}
            isNextImg
            contentItem={media}
            placeholder="blur"
            blurDataURL={getPixalImageDataUrl('#CCC')}
          />
        </a>
        <div className="article-container">
          {secondaryHeader && (
            <div className="secondary eyebrowSmall" {...inspectorMode(id, 'secondaryHeader')}>
              {secondaryHeader}
            </div>
          )}
          <a href={link}>
            <h2 {...inspectorMode(id, 'primaryHeader')}>{primaryHeader}</h2>
          </a>
          {publishedDate && (
            <div className="date utilityLarge" {...inspectorMode(id, 'publishedDate')}>
              {formattedDate}
            </div>
          )}
          {content && (
            <div className="article-content">
              <CmsRichText contentItem={content} />
            </div>
          )}
        </div>
      </div>
    );
  },
);

import { css, useTheme } from '@emotion/react';
import { Skeleton } from '../Skeleton';

const TimerSkeleton = () => {
  const theme = useTheme();
  const timerCss = css`
    ${theme.widgets.CmsTimer?.wrapper};
    .timer-content {
      width: 300px;
      margin-left: auto;
      margin-right: auto;
    }
  `;

  return (
    <div data-test="timerSkeleton" css={timerCss}>
      <span className="timer-headline">
        <Skeleton height={14} mt={2} mb={4} width={150} />
      </span>
      <h2 className="timer-content">
        <Skeleton height={34} mt={2} mb={1} />
      </h2>
    </div>
  );
};

export default TimerSkeleton;

import Link from 'next/link';
import Image from 'next/image';
import type { ProductProps } from 'tsconfig/types';
import { Icon } from 'common-ui/src/components/Icon/Icon';
import { css, useTheme } from '@emotion/react';
import { globals, productFlagImageUrl, PDP_PLP_MAPPING_LINKS } from 'common-ui';

export const WineStyleCountryFlagAU: React.FC<{ product: ProductProps }> = ({ product }) => {
  const countryName = product?.countryName || 'wine';
  const countryLink = PDP_PLP_MAPPING_LINKS[countryName]
    ? PDP_PLP_MAPPING_LINKS[countryName]
    : countryName.toLowerCase().split(' ').join('-');
  return (
    <ul className="wineStyle-country-au">
      {product.styleName && (
        <li className="wineStyle">
          <Icon kind="wine-glass" size="m" />
          <span className="wine-glass">{product.styleName}</span>
        </li>
      )}
      {product.countryName && (
        <li className="countryNameFlag">
          <Link prefetch={false} href={countryLink === 'wine' ? '/wine' : `/wine/${countryLink}-wine`}>
            <span className="countryFlag">
              <Image
                src={productFlagImageUrl(product?.countryName)}
                alt={product?.countryName}
                width={24}
                height={24}
              />
            </span>
            <span className="countryName">{product.countryName}</span>
          </Link>
        </li>
      )}
    </ul>
  );
};

export const WineDetails: React.FC<{ product: ProductProps }> = ({ product }) => {
  const theme = useTheme();
  const wrapper = css`
    ${theme.widgets.Product?.wineDetails}
  `;

  const countryName = product?.countryName || 'wine';
  const grapeName = product?.grapeName || 'wine';
  const countryLink = PDP_PLP_MAPPING_LINKS[countryName]
    ? PDP_PLP_MAPPING_LINKS[countryName]
    : countryName.toLowerCase().split(' ').join('-');
  const grapeLink = PDP_PLP_MAPPING_LINKS[grapeName]
    ? PDP_PLP_MAPPING_LINKS[grapeName]
    : grapeName.toLowerCase().split(' ').join('-');
  return (
    <div css={wrapper}>
      <ul>
        {product.styleName && globals.country !== 'au' && (
          <li>
            <Icon kind="wine-glass" size="m" /> {product.styleName}
          </li>
        )}
        {product.fineWineFlag && (
          <li>
            <Icon kind="diamond" size="m" /> Fine wine
          </li>
        )}
        {product.countryName && globals.country !== 'au' && (
          <li>
            <Link prefetch={false} href={countryLink === 'wine' ? '/wine' : `/wine/${countryLink}-wine`}>
              <Image
                src={productFlagImageUrl(product?.countryName)}
                alt={product?.countryName}
                width={16}
                height={16}
              />
              <span>{product.countryName}</span>
            </Link>
          </li>
        )}
        {product.shortTastingNotesColor && (
          <li>
            <Icon kind="droplet" size="m" />
            {product.shortTastingNotesColor
              .trim()
              .split(' ')
              .map(word => word.charAt(0).toUpperCase() + word.slice(1))
              .join(' ')}
          </li>
        )}
        {product.grapeName && (
          <li>
            <Link prefetch={false} href={grapeLink === 'wine' ? '/wine' : `/wine/${grapeLink}-wine`}>
              <Icon kind="grape" size="m" /> <span>{product.grapeName}</span>
            </Link>
          </li>
        )}
        {(globals.country === 'uk' || globals.country === 'au') && product.alcoholUnits > 0 && (
          <li>
            <Icon kind="units" size="m" /> {product.alcoholUnits} Units
          </li>
        )}
        {product.vegan && (
          <li>
            <Icon kind="vegan" size="m" /> Vegan
          </li>
        )}
        {product.alcoholPercent > 0 && (
          <li>
            <Icon kind="alcohol" size="m" /> {product.alcoholPercent}% ABV
          </li>
        )}
        {product.kosher && (
          <li>
            <Icon kind="kosher" size="m" /> Kosher
          </li>
        )}
        {product.skus?.[0]?.bottleVolume > 0 && (
          <li>
            <Icon kind="bottle-glass" size="m" /> {product.skus?.[0]?.bottleVolume} ml
          </li>
        )}
        {product.vegetarian && (
          <li>
            <Icon kind="vegetarian" size="m" /> Vegetarian
          </li>
        )}
        {product.drinkByDate && (
          <li>
            <Icon kind="calendar" size="m" /> {product.drinkByDate}
          </li>
        )}
        {product.biodynamic && (
          <li>
            <Icon kind="biodynamic" size="m" /> Biodynamic
          </li>
        )}
        {product.packagePioneer && (
          <li>
            <Icon kind="packaging-pioneer" size="m" /> Packaging Pioneer
          </li>
        )}
      </ul>
    </div>
  );
};

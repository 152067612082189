import { initCommerceApiWithGlobals } from 'common-api';
import { isBrowser, logger } from 'common-ui';
import type { CartResponse, LineItem, ProductProps } from 'tsconfig/types';

// Function to fetch Product details
export async function getProducts(newCartData: CartResponse): Promise<LineItem[] | void> {
  if (!isBrowser()) {
    logger.log('getProducts skipping as not in browser');
    return;
  }

  const commerceApi = initCommerceApiWithGlobals();

  // Fetch all products via product BFF
  const productItemCodes = newCartData?.lineItems?.map(item => item.sku.itemCode) || [];

  if (productItemCodes.length === 0) {
    return;
  }

  try {
    const itemCodeQueryParam = productItemCodes.join(','); // Join itemCodes with a comma
    const products: ProductProps[] = await commerceApi.Product.getAllProductData(itemCodeQueryParam);

    return newCartData.lineItems.map(cartData => {
      const matchingItem = products.find(product => product.itemCode === cartData.sku.productItemCode);
      return { ...cartData, product: matchingItem ? { ...matchingItem } : undefined };
    });
  } catch (error) {
    logger.error('Error fetching product data:', error);
    throw error;
  }
}

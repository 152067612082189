import { atom, selector, selectorFamily } from 'recoil';
import { effectConsoleLogAtomUpdates } from '../helpers/debug';
import type { CaseOrderHistory, WinePlanTypes } from 'tsconfig/types';
import { initCommerceApiWithGlobals } from 'common-api';
import { logger } from 'common-ui';

// Storing the upcomming case details in wine plan screen
export const winePlanUpcomingCaseAtom = atom<WinePlanTypes>({
  key: 'winePlanUpcomingCaseAtom', // It saves the upcoming case details data
  default: undefined,
  effects: [effectConsoleLogAtomUpdates],
});

//Selectors
//----------------------------------------------------------------------

export const selectUpcomingCaseData = selector({
  key: 'selectUpcomingCaseData',
  get: ({ get }) => {
    try {
      const upcomingCaseData = get(winePlanUpcomingCaseAtom);
      if (!!upcomingCaseData) {
        return upcomingCaseData;
      }
      return undefined;
    } catch (error) {
      throw new Error('Something went wrong');
    }
  },
});

export const caseOrderHistoryAtom = atom<CaseOrderHistory>({
  key: 'caseOrderHistoryAtom', // It saves the case order history data
  default: undefined,
  effects: [effectConsoleLogAtomUpdates],
});

export const selectCaseOrderHistoryData = selector({
  key: 'selectCaseOrderHistoryData',
  get: ({ get }) => {
    try {
      const caseOrderHistoryData = get(caseOrderHistoryAtom);
      if (!!caseOrderHistoryData) {
        return caseOrderHistoryData;
      }
      return undefined;
    } catch (error) {
      throw new Error('Something went wrong');
    }
  },
});

export const fetchWinePlanSubscriptionDetails = selectorFamily({
  key: 'winePlanSubscriptionDetailsSelector',
  get: (subscriptionId: string) => async () => {
    try {
      const commerceApi = initCommerceApiWithGlobals();
      return await commerceApi.WinePlan.getSupportedFrequencies(subscriptionId);
    } catch (error) {
      logger.log('API ERROR', error);
    }
  },
});

export const userDeliveryAddressAtom = atom<any>({
  key: 'winePlanUserDeliveryAddressAtom',
  default: {
    isLoading: false,
    data: [],
  },
  effects: [effectConsoleLogAtomUpdates],
});

export const userSelectedAddressAtom = atom({
  key: 'winePlanUserSelectedAddressAtom',
  default: {
    isLoading: false,
    hasError: false,
    data: null,
  },
  effects: [effectConsoleLogAtomUpdates],
});

export const wineplanPincodeSearchAtom = atom<any>({
  key: 'wineplanPincodeSearchAtom',
  default: {
    isLoading: false,
    hasError: false,
    data: null,
  },
  effects: [effectConsoleLogAtomUpdates],
});

export const wineplanUpdateDeliveryAddressAtom = atom({
  key: 'wineplanUpdateDeliveryAddressAtom',
  default: {
    isLoading: false,
    hasError: false,
    isAddressSaved: false,
  },
  effects: [effectConsoleLogAtomUpdates],
});

export const wineplanUseDeliveryAddressAtom = atom({
  key: 'wineplanUseDeliveryAddressAtom',
  default: {
    isLoading: false,
    hasError: false,
    isAddressUpdated: false,
    selectedAddressId: '',
  },
  effects: [effectConsoleLogAtomUpdates],
});

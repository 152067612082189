import { setRecoil } from 'recoil-nexus';
import { cartAtom } from '../atoms/cartAtom';

let cartErrorTimeout: NodeJS.Timeout | null = null;

export function clearCartError(delay = 5000): void {
  // Clear any existing timeout
  if (cartErrorTimeout) {
    clearTimeout(cartErrorTimeout);
  }

  // Set a new timeout to clear the cartError state
  cartErrorTimeout = setTimeout(() => {
    setRecoil(cartAtom, prevState => ({
      ...prevState,
      cartError: {
        hasCartError: false,
        cartErrorMessage: '',
      },
    }));
  }, delay);
}

import { TrendingItems } from '@algolia/recommend-react';
import recommend from '@algolia/recommend';
import { css, useTheme } from '@emotion/react';
import { Carousel } from '@mantine/carousel';
import React, { useEffect, useMemo, useState, memo } from 'react';
import { globals, ItemCard } from 'common-ui';

const sliderSettings = [
  { maxWidth: 'xl', slideSize: '25%' },
  { maxWidth: 'lg', slideSize: '33.33333%' },
  { maxWidth: 'md', slideSize: '50%', slideGap: 'sm' },
  { maxWidth: 'xs', slideSize: '100%', slideGap: 0 },
];

type ListViewProps = {
  items: any[];
  itemComponent: any;
  isMiniCart: boolean;
  cmsCarousel: object;
  slidestoShow: number;
};

type TrendingProductItemsProps = {
  isMiniCart?: boolean;
  showImageZoom?: boolean;
  wineType?: string[] | null;
  grape?: string[] | null;
  country?: string[] | null;
  productType?: string[] | null;
  awardWinner?: boolean;
  giftItems?: boolean;
  offerItems?: boolean;
  fineWine?: boolean;
};

const ListView = React.memo(({ items, itemComponent, isMiniCart, cmsCarousel, slidestoShow }: ListViewProps) => {
  const tempSliderSetting = isMiniCart ? [] : sliderSettings;
  const showControls = Array.isArray(items) && items.length > (isMiniCart ? 1 : slidestoShow);

  return items.length > 0 ? (
    <Carousel
      withIndicators={showControls}
      withControls={showControls}
      loop={showControls}
      draggable={showControls}
      slidesToScroll={1}
      slideSize={isMiniCart ? '100%' : '20%'}
      align="start"
      slideGap="md"
      controlsOffset="md"
      className="trending-products-carousel"
      styles={cmsCarousel}
      breakpoints={tempSliderSetting}
    >
      {items.map(item => (
        <Carousel.Slide key={item.objectID}>{React.createElement(itemComponent, { item })}</Carousel.Slide>
      ))}
    </Carousel>
  ) : null;
});

const TrendingProductItem = React.memo(
  ({ item, isMiniCart, showImageZoom }: { item: any; isMiniCart: boolean; showImageZoom: boolean }) => {
    return <ItemCard product={item} showRating={isMiniCart ? false : true} showImageZoom={showImageZoom} />;
  },
);

const HeaderComponent = ({ isMiniCart = false }: { isMiniCart?: boolean }) => {
  return (
    <div className="text-center">
      <h3 className="auc-Recommend-title">
        {isMiniCart ? (globals.country === 'us' ? 'May We Recommend' : 'Top Trending Wines') : 'Trending'}
      </h3>
    </div>
  );
};

export const TrendingProductItems = memo(
  ({
    isMiniCart = false,
    showImageZoom = true,
    wineType = null,
    grape = null,
    country = null,
    productType = null,
    awardWinner = false,
    giftItems = false,
    offerItems = false,
    fineWine = false,
  }: TrendingProductItemsProps) => {
    const recommendClient = useMemo(
      () => recommend((globals?.algoliaAppId || '') as string, (globals?.algoliaSearchKey || '') as string),
      [],
    );
    const indexName = (globals?.algoliaIndexName || '') as string;

    const theme = useTheme();
    const productItemTrending = useMemo(
      () => css`
        ${theme.widgets.Cart?.productItemsTrending}
      `,
      [theme],
    );

    const [slidestoShow, setSlidesToShow] = useState(1);

    useEffect(() => {
      const handleResize = () => {
        const windowWidth = window.innerWidth;
        let noOfSlides = 5;
        switch (true) {
          case windowWidth < 576:
            noOfSlides = 1;
            break;
          case windowWidth < 992:
            noOfSlides = 2;
            break;
          case windowWidth < 1200:
            noOfSlides = 3;
            break;
          case windowWidth < 1408:
            noOfSlides = 4;
            break;
          default:
            noOfSlides = 5;
        }
        setSlidesToShow(noOfSlides);
      };

      handleResize();
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }, []);

    const createFilterString = (field: string, values: Array<string>) => {
      const filterString = values.map(value => `${field}:'${value}'`).join(` OR `);
      return ` AND ${values.length > 1 ? `(${filterString})` : filterString}`;
    };

    let filters = 'inStock:true';
    if (wineType?.length) {
      filters += createFilterString('wineType', wineType);
    }
    if (country?.length) {
      filters += createFilterString('country', country);
    }
    if (grape?.length) {
      filters += createFilterString('grape', grape);
    }
    if (productType?.length) {
      const productTypeFilter = productType.map(type => {
        return type.replace('Cases', 'Case').replace('Single Bottles', 'Wine');
      });
      filters += createFilterString('productType', productTypeFilter);
    }
    if (awardWinner) {
      filters += ` AND awardWinner:${awardWinner}`;
    }
    if (giftItems) {
      filters += ` AND giftFlag:${giftItems}`;
    }
    if (offerItems) {
      filters += ` AND offers:'On Offer'`;
    }
    if (fineWine) {
      filters += ` AND fineWine:${fineWine}`;
    }

    return (
      <div data-testid="trending-items" css={productItemTrending} style={{ position: 'relative' }}>
        <TrendingItems
          recommendClient={recommendClient}
          indexName={indexName}
          queryParameters={{
            filters,
          }}
          itemComponent={props => (
            <TrendingProductItem {...props} isMiniCart={isMiniCart} showImageZoom={showImageZoom} />
          )}
          view={props => {
            return (
              <ListView
                {...props}
                isMiniCart={isMiniCart}
                cmsCarousel={theme.widgets.CmsCarousel ?? {}}
                slidestoShow={slidestoShow}
              />
            );
          }}
          maxRecommendations={5}
          headerComponent={props => {
            return props?.recommendations?.length > 0 ? <HeaderComponent isMiniCart={isMiniCart} /> : <span></span>;
          }}
        />
      </div>
    );
  },
);

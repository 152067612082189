import { Theme } from '@emotion/react';
import { buildTheme } from 'common-ui';
import merge from 'lodash/merge';
import { CommonThemeVars, ThemeCreateFunction } from 'tsconfig/types';
import { buildThemeMixinsUSOsw, buildThemeWidgetStylesUSOsw, buildThemeVarsUSOsw } from 'dw-us-osw/theme';
const createTheme: ThemeCreateFunction = () =>
  buildTheme(
    (vars: Pick<Theme, CommonThemeVars>) => {
      return merge({}, buildThemeVarsUSOsw(vars));
    },
    (vars: Omit<Theme, 'mixins' | 'widgets'>) => {
      return buildThemeMixinsUSOsw(vars);
    },
    (vars: Omit<Theme, 'widgets'>) => {
      return buildThemeWidgetStylesUSOsw(vars);
    },
  );
export default createTheme;

/* eslint-disable jsx-a11y/anchor-is-valid */
import { css, useTheme } from '@emotion/react';
import type { ProductProps } from 'tsconfig/types';

import { CountryMockImg } from 'common-ui/src/svg';
import { Icon } from 'common-ui/src/components/Icon/Icon';
import { Rating } from 'common-ui/src/components/Rating/Rating';
import { handleDLClickEvent } from 'common-ui';

import { useDisclosure } from '@mantine/hooks';
import { Modal } from '@mantine/core';

export const TastingNote = (props: { product: ProductProps }) => {
  const { product } = props;

  const [opened, { close }] = useDisclosure(false);

  const theme = useTheme();
  const zoom = css`
    ${theme.widgets.Product?.zoom}
  `;

  return (
    <>
      <Modal
        css={zoom}
        opened={opened}
        size="xl"
        className="tasting-note-modal"
        onClose={close}
        transitionProps={{ transition: 'fade', duration: 200 }}
      >
        <div>
          <h1 className="main-title">
            {product.name} {product.vintage}
          </h1>

          <div className="sub-title">{product.appellationName}</div>

          <div className="rating-country-container">
            <div className="rating">
              <Rating rating={product?.averageOverallRating} />
              <span className="rating-cout">{product?.averageOverallRating}/5</span>
              <span className="review-count">
                {product?.totalReviewCount} {product?.totalReviewCount <= 1 ? 'review' : 'reviews'}
              </span>
            </div>

            <div className="country">
              <CountryMockImg />
              {product.countryName}
            </div>
          </div>

          <div className="description-container">{product.description}</div>
        </div>
      </Modal>

      <a
        href="#"
        onClick={e => {
          e.preventDefault();
          window.print();
          // open();
          handleDLClickEvent('print the label', 'print');
        }}
      >
        <Icon kind="print" size="m" />
      </a>
    </>
  );
};

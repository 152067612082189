import type { DLClickEventProps, DLProductEventProps, ProductProps } from 'tsconfig/types';
import { adobeDataLayer } from './AdobeDataLayer';
import { adobeProductMapData } from './adobeProductMapData';

// Define constants for repeated strings
const ADD_TO_CART = 'add to cart';
const UPDATE_CART = 'update cart';
const ADD_TO_BASKET_TEXT = 'Add to Basket';

export const handleDLProductClickEvent = (
  clickType: string,
  productData: ProductProps,
  quantity: number,
  page?: string,
  productItemCode?: string,
) => {
  const productDetailInfo = [adobeProductMapData(productData, quantity, productItemCode)];
  const itemCode = productData?.skus?.[0]?.itemCode;
  const price =
    productData?.enPrimeur || productData?.enPrimeurFlag
      ? productData?.skus?.[0]?.enPrimeurPrice
      : productData?.skus?.[0]?.salePrice || 0;
  const PAGE_TYPE = adobeDataLayer.getPageType();

  // Object for cart page
  const addToCartClick: DLClickEventProps = {
    clickAction: UPDATE_CART,
    clickDescription: `${PAGE_TYPE}:${UPDATE_CART}`,
    clickLocation: `${PAGE_TYPE}`,
    clickName: UPDATE_CART,
    clickText: 'add',
    errorMessage: '',
  };

  // Object for PDP pages
  const productAddToCartClick: DLClickEventProps = {
    clickAction: ADD_TO_CART,
    clickDescription: `${PAGE_TYPE}:${ADD_TO_CART}`,
    clickLocation: `${PAGE_TYPE}`,
    clickName: ADD_TO_CART,
    clickText: ADD_TO_BASKET_TEXT,
    errorMessage: '',
  };

  // Object for Initiating cart
  const clickInitiateCart: DLClickEventProps = {
    clickAction: 'add to empty cart',
    clickDescription: `${PAGE_TYPE}:add to empty cart`,
    clickLocation: `${PAGE_TYPE}`,
    clickName: 'add to empty cart',
    clickText: ADD_TO_BASKET_TEXT,
    errorMessage: '',
  };

  // Object for cart page remove
  const clickRemoveFromCart: DLClickEventProps = {
    clickAction: UPDATE_CART,
    clickDescription: `${PAGE_TYPE}:${UPDATE_CART}`,
    clickLocation: `${PAGE_TYPE}`,
    clickName: UPDATE_CART,
    clickText: 'remove',
    errorMessage: '',
  };

  // Object for mini cart page
  const addToCartMiniCartClick: DLClickEventProps = {
    clickAction: ADD_TO_CART,
    clickDescription: `${PAGE_TYPE}:${ADD_TO_CART}`,
    clickLocation: 'mini-cart',
    clickName: ADD_TO_CART,
    clickText: ADD_TO_CART,
    errorMessage: '',
  };

  let click: DLClickEventProps = {};
  const product: DLProductEventProps = {
    ...productDetailInfo,
    quantity: quantity,
    itemcode: itemCode,
    price: price,
  };

  switch (clickType) {
    case 'InitiateCart':
      click = clickInitiateCart;
      break;
    case 'addToCart':
      click = page === 'cart' ? addToCartClick : page === 'miniCart' ? addToCartMiniCartClick : productAddToCartClick;
      break;
    case 'removeFromCart':
      click = clickRemoveFromCart;
      break;
    default:
      return;
  }
  adobeDataLayer.clickEvent(clickType, click, {
    product,
  });
};

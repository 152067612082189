import type { RichTextEntryType } from 'tsconfig/types';

// Code to add padding for element with Backgroud color from Rich-text
const addBackgroundClass = (element: HTMLElement) => {
  const inlineStyle = element.getAttribute('style');

  if (inlineStyle && inlineStyle.includes('background')) {
    element.classList.add('richtext-background');
  }
};

const traverseDOM = (node: Node) => {
  if (node?.nodeType === 1) {
    addBackgroundClass(node as HTMLElement);

    for (let i = 0; i < node?.childNodes?.length; i++) {
      traverseDOM(node?.childNodes[i]);
    }
  }
};

export const parseHtmlContent = (htmlContent: string) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlContent, 'text/html');
  const { body } = doc;
  traverseDOM(body);

  return body.innerHTML;
};

export function contentToSanitize(contentItem: RichTextEntryType): string {
  return typeof contentItem?.fields?.content === 'string' &&
    (contentItem?.fields?.content as string).replace(/<[^>]*>/g, '').trim()
    ? contentItem?.fields?.content
    : '';
}

import { atom, selector, useRecoilState } from 'recoil';
import { initCommerceApiWithGlobals } from 'common-api';
import { effectConsoleLogAtomUpdates } from '../helpers/debug';
import type { UserDetails, UserSummaryDetails } from 'tsconfig/types.d';
import { isBrowser } from '../helpers/utils';
import { userStatus } from '../helpers/user.utils';
import { logger } from 'common-ui';

//Atom
//----------------------------------------------------------------------
export const userAtom = atom<UserDetails>({
  key: 'userAtom',
  default: undefined,
  effects: [effectConsoleLogAtomUpdates],
});

//Selectors
//----------------------------------------------------------------------
export const selectUserProfile = selector({
  key: 'selectUserProfile',
  get: ({ get }) => {
    return get(userAtom);
  },
});

export const selectUserDetails = selector<UserSummaryDetails>({
  key: 'selectUserDetails',

  get: ({ get }) => {
    const userData = get(userAtom);

    const { loginStatus, loginType } = userStatus(userData?.securityStatus);
    return {
      firstName: userData?.summaryDetails?.firstName,
      lastName: userData?.summaryDetails?.lastName,
      salutation: userData?.summaryDetails?.salutation,
      profileId: userData?.summaryDetails?.profileId,
      brandAccountNumber: userData?.summaryDetails?.brandAccountNumber,
      wineFriendId: userData?.summaryDetails?.wineFriendId,
      businessPartner: userData?.summaryDetails?.businessPartner,
      emailAddress: userData?.summaryDetails?.emailAddress,
      brand: userData?.summaryDetails?.brand,
      loginStatus,
      loginType,
      shippingAddress: userData?.trustedDetails?.shippingAddress,
      billingAddress: userData?.trustedDetails?.billingAddress,
      subscriptions: userData?.trustedDetails?.subscriptions,
      userLoded: userData?.userLoded,
    };
  },
});

export const selectUserIsLoggedIn = selector({
  key: 'selectUserIsLoggedIn',
  get: ({ get }) => {
    const userData = get(userAtom);
    //TODO: refactor this when we have easier acces to the User object schema
    return userData?.summaryDetails;
  },
});

export const loadedUser = selector({
  key: 'loadedUser',
  get: ({ get }) => {
    const userData = get(userAtom);
    if (!userData) {
      return;
    }
    return userData;
  },
});

export const selectUserDeliveryAddress = selector({
  key: 'selectUserDeliveryAddress',
  get: ({ get }) => {
    const userData = get(userAtom);
    return userData?.trustedDetails?.shippingAddress || [];
  },
});

/**
 * Selects whether the user has an unlimited subscription.
 *
 * @returns {boolean} True if the user has an active unlimited subscription, false otherwise.
 * Usage:
 * import { selectUserIsUnlimited } from 'common-state';
 * const isUnlimited = useRecoilValue(selectUserIsUnlimited);
 *
 */
export const selectUserIsUnlimited = selector({
  key: 'selectUserIsUnlimited',
  get: ({ get }) => {
    const userData = get(userAtom);
    const { subscriptions } = userData?.trustedDetails || {};

    if (subscriptions) {
      // User must have an active unlimited subscription
      const result = subscriptions.find(
        ({ schemeTypes, type, status }) =>
          schemeTypes?.includes('FreeShip') && type === 'GENERAL' && (status === 'REQUESTED' || status === 'ACTIVE'),
      );
      return !!result;
    }
    return false;
  },
});

//Hooks
//----------------------------------------------------------------------
export const useInitUserState = () => {
  const [currentUserData, setUserData] = useRecoilState(userAtom);

  return async () => {
    if (isBrowser()) {
      if (currentUserData) {
        setUserData({ ...currentUserData, userLoded: true });
        logger.log('skipping init fetch of user data as already defined');
        return;
      }

      try {
        const commerceApi = initCommerceApiWithGlobals();
        const newUserData = await commerceApi.User.get();
        setUserData({ ...newUserData?.response, userLoded: true });
      } catch (e) {
        logger.log('API ERROR', e);
      }
    } else {
      logger.log('useInitUserState skipping fetch as not in browser');
    }
  };
};

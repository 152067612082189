import { isBrowser, logger, globals } from 'common-ui';
import { getRecoil } from 'recoil-nexus';
import type { ErrorResponse } from 'tsconfig/types';
import { selectUserDetails } from '../atoms/userAtom';
import { createNewCart } from '../atoms/cartAtom';
import { getAnonymousToken, refreshAuthToken } from '../atoms/authAtom';
import { notifications } from '@mantine/notifications';
import { clearCartError } from './clearCartError';

const showBasketExpiredNotification = () => {
  notifications.show({
    message: `Your ${globals.country === 'uk' ? 'basket' : 'cart'} has expired. Please start your order again.`,
    autoClose: 3000,
    top: 15,
    color: 'red',
  });
};

// Variables to track error count
let errorCount = 0;
let error404Count = 0;

export async function commonHandlerForWebApiRequestError(
  error: unknown,
  doAction?: () => Promise<void>,
): Promise<void> {
  if (!isBrowser()) {
    logger.log('Skipping fetch as not in browser');
    return;
  }

  const apiError = error as ErrorResponse;
  const { loginType } = getRecoil(selectUserDetails);
  const { status } = apiError?.response || {};

  // Handle authorization-related errors (401, 403)
  if ((status === 401 || status === 403) && errorCount < 2) {
    errorCount++;
    return handleAuthError(status, loginType, doAction);
  }

  // Handle 404 (Not Found) errors
  if (status === 404 && error404Count < 2) {
    error404Count++;
    return handleAuthError(404, loginType, createNewCart);
  }

  throw apiError;
}

async function handleAuthError(status: number, loginType?: string, doAction?: () => Promise<void>): Promise<void> {
  if (status === 403 && loginType !== 'unidentified') {
    return handleRegisteredUser(createNewCart);
  }

  return loginType === 'unidentified' ? handleAnonymousUser() : handleRegisteredUser(doAction);
}

async function handleAnonymousUser(): Promise<void> {
  try {
    showBasketExpiredNotification();
    await getAnonymousToken();
    await createNewCart();
    errorCount = 0;
    clearCartError(1000);
  } catch (error) {
    throw error;
  }
}

async function handleRegisteredUser(doAction?: () => Promise<void>): Promise<void> {
  try {
    const refreshSuccess = await refreshAuthToken();
    if (refreshSuccess && doAction) {
      if (doAction === createNewCart) {
        showBasketExpiredNotification();
      }
      const result = await doAction();
      errorCount = 0;
      return result;
    }
    errorCount = 0;
  } catch (error: unknown) {
    const apiError = error as ErrorResponse;
    if (apiError?.response?.status === 404) {
      await commonHandlerForWebApiRequestError(error);
    } else {
      throw error;
    }
  }
}

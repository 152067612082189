import React from 'react';
import { css, useTheme } from '@emotion/react';
import { ChevronUp, ChevronDown } from '../../svg';
import { inspectorMode } from 'common-ui';
import RichText from '../RichText/RichText';

export interface AccordionProps {
  id: string;
  entryTitle: string;
  content: string;
  index: number;
  activeIndex: number | null;
  handleClick: (index: number) => void;
}

const Accordion: React.FC<AccordionProps> = ({ id, entryTitle, content, index, activeIndex, handleClick }) => {
  const theme = useTheme();

  const accordionWrapper = css`
    ${theme.widgets.Search?.accordionWrapper}
  `;

  return (
    <div>
      <div css={accordionWrapper}>
        <div
          className="accordion-item"
          onClick={() => handleClick(index)}
          onKeyDown={e => e.key === 'Enter' && handleClick(index)}
          tabIndex={0}
          role="button"
        >
          <div className="accordion-title" {...inspectorMode(id, 'entryTitle')}>
            <h3 className="title">{entryTitle}</h3>
            <div className="accordion-icon">{activeIndex === index ? <ChevronUp /> : <ChevronDown />}</div>
          </div>
          <div className={`accordion-content ${activeIndex === index ? 'show-accordion' : ''}`}>
            <RichText sysId={id} content={content} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Accordion;
